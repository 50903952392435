import React, { ReactNode } from "react";
import { Button, ButtonVariants } from "../../../../elements/Button";
import { styles } from "./TextContent.style";

export interface ITextContentProps {
  title?: string;
  ctaLabel?: string;
  ctaLink?: string;
  withImage?: boolean;
  children: ReactNode;
}

export function TextContent({ title, ctaLabel, ctaLink, withImage = false, children }) {
  const showCta = ctaLabel && ctaLink;

  return (
    <div css={styles.contentWrapper(withImage)}>
      {title && <h2>{title}</h2>}
      <div css={styles.textWrapper(title)}>{children}</div>
      {showCta && (
        <div css={styles.buttonWrapper}>
          <Button variant={ButtonVariants.Outlined} href={ctaLink}>
            {ctaLabel}
          </Button>
        </div>
      )}
    </div>
  );
}
