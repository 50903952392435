import React, { ReactNode, useMemo } from "react";
import { styles } from "./TextImageWidget.style";
import { ImageContent } from "./components/ImageContent";
import { TextContent } from "./components/TextContent";
import { VideoContent } from "./components/VideoContent";
import { VideoPlayer, Video } from "./components/VideoPlayer";
import { Container } from "../../elements/Container";

export interface ITextImageWidgetProps {
  title?: string;
  image?: {
    url: string;
    width?: number;
    height?: number;
    title?: string;
  };
  mobileImage?: {
    url: string;
    width?: number;
    height?: number;
    title?: string;
  };
  videoUrl?: string;
  videoTitle?: string;
  contentfulVideo?: Video;
  isAnimation?: boolean;
  ctaLabel?: string;
  ctaLink?: string;
  inverted?: boolean;
  children: ReactNode;
  optionalTitle: string;
  isLoop: boolean;
}

export function TextImageWidget({
  title,
  videoUrl,
  videoTitle,
  contentfulVideo,
  isAnimation,
  isLoop,
  image,
  ctaLabel,
  ctaLink,
  inverted,
  optionalTitle,
  mobileImage,
  children,
}: ITextImageWidgetProps) {
  const isSampleReportStyle = !!mobileImage && !!optionalTitle;
  const renderMedia = useMemo(() => {
    if (image && image.url) {
      return <ImageContent image={image} mobileImage={mobileImage} isSampleReportStyle={isSampleReportStyle} />;
    } else if (videoUrl) {
      return <VideoContent videoUrl={videoUrl} videoTitle={videoTitle} />;
    } else if (contentfulVideo) {
      return <VideoPlayer video={contentfulVideo} isAnimation={isAnimation} isLoop={isLoop} />;
    }
    return null;
  }, [image, videoUrl, contentfulVideo]);

  return (
    <Container>
      <div css={styles.root(inverted)}>
        {!!optionalTitle && <h2 css={styles.displayOnMobile()}>{optionalTitle}</h2>}
        {renderMedia}
        <div css={styles.textWrapper()}>
          {!!optionalTitle && <h2 css={styles.displayOnDesktop()}>{optionalTitle}</h2>}
          <TextContent
            title={title}
            ctaLabel={ctaLabel}
            ctaLink={ctaLink}
            withImage={Boolean(image && image.url) || Boolean(videoUrl) || Boolean(contentfulVideo)}
          >
            {children}
          </TextContent>
        </div>
      </div>
    </Container>
  );
}
