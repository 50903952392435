import { css } from "@emotion/react";
import { mediaQuery, spacing } from "../../../../core/styles";

export const styles = {
  wrapper: () =>
    css({
      [mediaQuery.smallerThanTablet]: {
        position: "relative",
        width: "100%",
        overflow: "hidden",
        paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
        marginBottom: spacing.baseUnitMobile2,
        video: {
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
          border: "none",
        },
      },
    }),
};
