import { css } from "@emotion/react";
import { spacing, mediaQuery } from "../../core/styles";

export const styles = {
  root: (inverted: boolean) =>
    css({
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: `0 ${spacing.baseUnitMobile3}`,
      [mediaQuery.desktopOrLarger]: {
        flexDirection: inverted ? "row-reverse" : "row",
      },
    }),
  displayOnMobile: () =>
    css({
      textAlign: "center",
      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
  displayOnDesktop: () =>
    css({
      display: "none",
      [mediaQuery.desktopOrLarger]: {
        display: "initial",
      },
    }),
  textWrapper: () =>
    css({
      maxWidth: "528px",
      textAlign: "center",
    }),
};
