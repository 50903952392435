import React, { useMemo } from "react";
import { styles } from "./VideoContent.style";
import { useInView } from "react-intersection-observer";

export interface IVideoContentProps {
  videoUrl: string;
  videoTitle?: string;
}

export function VideoContent({ videoUrl, videoTitle }) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const url = useMemo(() => {
    if (inView) {
      return `${videoUrl}?cc_load_policy=1&rel=0&showinfo=1&fs=1`;
    }
    return null;
  }, [inView]);

  return (
    <div ref={ref} css={styles.wrapper}>
      <iframe width="560" height="315" src={url} allowFullScreen title={videoTitle}></iframe>
    </div>
  );
}
