import React from "react";
import { TextImageWidget } from "@carfax/web-ui/components/TextImageWidget";
import { ModuleTextWithImage } from "contentful/PageModularModule";
import RichTextWrapper from "./components/RichTextWrapper";
import SampleReport from "components/SampleReport";
import { Button, ButtonVariants } from "@carfax/web-ui/elements/Button";

interface Props {
  module: ModuleTextWithImage;
}

const TextImage: React.FC<Props> = ({ module }) => {
  return (
    <TextImageWidget
      videoUrl={module.videoUrl}
      videoTitle={module.videoTitle}
      contentfulVideo={module.contentfulVideo}
      image={module.image}
      ctaLabel={module.ctaLabel}
      ctaLink={module.ctaLink}
      inverted={module.inverted}
      isAnimation={module.isAnimation}
      optionalTitle={module.optionalTitle}
      mobileImage={module.mobileImage}
      isLoop={module.isLoop}
    >
      <RichTextWrapper content={module.text} textAlign={module.textAlign} />
      {!!module.sampleReportButtonText && (
        <SampleReport
          optionalDesktopReport={module.optionalDesktopReport}
          optionalMobileReport={module.optionalMobileReport}
        >
          <Button variant={ButtonVariants.Outlined}>{module.sampleReportButtonText}</Button>
        </SampleReport>
      )}
    </TextImageWidget>
  );
};

export default TextImage;
