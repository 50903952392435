import { css } from "@emotion/react";
import { mediaQuery } from "../../../../core";

export const styles = {
  imageWrapper: () =>
    css({
      maxWidth: "528px",
      width: "100%",
    }),
  imageMobile: () =>
    css({
      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
  imageDesktop: () =>
    css({
      display: "none",
      [mediaQuery.desktopOrLarger]: {
        display: "initial",
      },
    }),
};
