import React from "react";
import { styles } from "./ImageContent.style";
import { Image } from "../../../../elements/Image";

export interface IImageContentProps {
  image: {
    url: string;
    width?: number;
    height?: number;
    title?: string;
  };
  mobileImage: {
    url: string;
    width?: number;
    height?: number;
    title?: string;
  };
  isSampleReportStyle: boolean;
}

export function ImageContent({ image, mobileImage, isSampleReportStyle }) {
  return (
    <div css={styles.imageWrapper()}>
      {isSampleReportStyle ? (
        <>
          <div css={styles.imageMobile()}>
            <Image image={mobileImage} />
          </div>
          <div css={styles.imageDesktop()}>
            <Image image={image} />
          </div>
        </>
      ) : (
        <Image image={image} />
      )}
    </div>
  );
}
