import React, { useMemo } from "react";
import { styles } from "./VideoPlayer.style";
import { useInView } from "react-intersection-observer";

export interface Video {
  url: string;
  title?: string;
  description?: string;
}

export interface IVideoContentProps {
  video: Video;
  isAnimation: boolean;
  isLoop: boolean;
}

export const VideoPlayer: React.FC<IVideoContentProps> = ({ video, isAnimation, isLoop }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const url = useMemo(() => {
    if (inView) {
      return video.url;
    }
    return null;
  }, [inView]);

  const animationSetting = isAnimation && {
    autoPlay: true,
    muted: true,
    loop: isLoop,
    controls: false,
    playsInline: true,
    title: "",
  };

  return (
    <div ref={ref} css={styles.wrapper}>
      <video
        title={video.title}
        disablePictureInPicture={true}
        controlsList="noplaybackrate nodownload"
        controls
        width="560"
        height="315"
        {...animationSetting}
      >
        {url && <source src={url} type="video/mp4" />}
      </video>
    </div>
  );
};
